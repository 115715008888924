import { FunctionComponent, SVGProps } from 'react';

export const BrowsersSolidIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            width="576"
            height="512"
            viewBox="0 0 576 512"
            fill="#3D56E0"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M160 0C124.7 0 96 28.7 96 64V352c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H160zM280 72H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H280c-13.3 0-24-10.7-24-24s10.7-24 24-24zM224 96a32 32 0 1 1 -64 0 32 32 0 1 1 64 0zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120V376c0 75.1 60.9 136 136 136H456c13.3 0 24-10.7 24-24s-10.7-24-24-24H136c-48.6 0-88-39.4-88-88V120z" />
        </svg>
    );
};

export default BrowsersSolidIcon;
