'use client';

import { FunctionComponent, useMemo } from 'react';
import EducatorSignUpButtonLink, {
    EducatorSignUpButtonLinkProps,
} from '../../../components/EducatorSignUpButtonLink';
import { useIam } from '../../../services/IAm/hooks';

export type EducatorSignUpButtonProps = EducatorSignUpButtonLinkProps;

export const EducatorSignUpButton: FunctionComponent<EducatorSignUpButtonProps> = ({
    className,
    ...props
}) => {
    const { iam } = useIam();
    const label = useMemo(() => {
        if (!iam.authenticated) {
            return undefined;
        }

        return 'Create New Account';
    }, [iam]);

    return (
        <EducatorSignUpButtonLink preset="primary" className={className} {...props}>
            {label}
        </EducatorSignUpButtonLink>
    );
};
