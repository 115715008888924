import { FunctionComponent, SVGProps } from 'react';

export const BookOpenCoverSolidIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            width="640"
            height="512"
            viewBox="0 0 640 512"
            fill="#3D56E0"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M304 32V408L96 368V0L304 32zM64 400l256 51.2L576 400V28.8L640 16V448L320 512 0 448V16L64 28.8V400zm272 8V32L544 0V368L336 408z" />
        </svg>
    );
};

export default BookOpenCoverSolidIcon;
