'use client';
import { FunctionComponent, ReactElement, useRef, useState } from 'react';
import SideBySide from '../SideBySide';
import styles from './styles.module.scss';
import BookOpenCoverSolidIcon from '../../../components/Icons/BookOpenCoverSolidIcon';
import UserGroupSimpleSolidIcon from '../../../components/Icons/UserGroupSimpleSolidIcon';
import BrowsersSolidIcon from '../../../components/Icons/BrowsersSolidIcon';
import MoneyBillWaveSolidIcon from '../../../components/Icons/MoneyBillWaveSolidIcon';
import InboxSolidIcon from '../../../components/Icons/InboxSolidIcon';
import { CameraHomeSolidIcon, EnvelopeSolidIcon, StarSolidIcon } from '../../../components/Icons';
import TicketSolidIcon from '../../../components/Icons/TicketSolidIcon';
import CalendarUsersSolidIcon from '../../../components/Icons/CalendarUsersSolidIcon';
import CalendarsSolidIcon from '../../../components/Icons/CalendarsSolidIcon';
import FileCertificateSolidIcon from '../../../components/Icons/FileCertificateSolidIcon';
import ArrowsRepeatSolidIcon from '../../../components/Icons/ArrowsRepeatSolidIcon';
import BallotCheckSolidIcon from '../../../components/Icons/BallotCheckSolidIcon';
import BadgePercentSolidIcon from '../../../components/Icons/BadgePercentSolidIcon';
import GraduationCapSolidIcon from '../../../components/Icons/GraduationCapSolidIcon';
import classNames from 'classnames';

type TabPropTypes = {
    label: string;
    onClick: () => void;
    isSelected: boolean;
    icon: ReactElement;
};

const Tab: FunctionComponent<TabPropTypes> = ({ label, onClick, isSelected, icon }) => {
    return (
        <button
            onClick={onClick}
            className={[styles.tab, isSelected ? styles.selected_tab : ''].join(' ')}
        >
            <div className={styles.icon_container}>{icon}</div>
            <span className={styles.label}>{label}</span>
        </button>
    );
};

type Section = {
    key: string;
    label: string;
    icon: ReactElement;
    content: ReactElement;
};

const SECTIONS: Section[] = [
    {
        key: 'events',
        label: 'Events',
        icon: <TicketSolidIcon height={20} width={20} />,
        content: (
            <SideBySide
                image={{
                    src: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/FeaturesCarousel/events.png`,
                    alt: 'Cademy events depiction',
                }}
                direction="rtl"
                heading="Plan, manage, and promote your events like a pro"
                text="Host successful events with tools to help you manage everything from ticketing to promotion. Set up in-person or online events, sell tickets, track attendance, and send reminders — all from one dashboard."
            />
        ),
    },
    {
        key: 'courses',
        label: 'Courses',
        icon: <GraduationCapSolidIcon height={20} width={20} />,
        content: (
            <SideBySide
                animation="builder"
                heading="Build, run and promote in-person & online courses"
                text="With our course builder, you can create courses and events that run online or in-person, group or solo, private or public, open or on-request, on-demand or scheduled, recurring or one-off... no matter the format, you can host it on Cademy."
                direction="rtl"
                sectionClass={styles.anim_section}
                animationContainerClass={styles.anim_builder_container}
            />
        ),
    },
    {
        key: 'scheduling',
        label: 'Scheduling',
        icon: <CalendarUsersSolidIcon height={20} width={20} />,
        content: (
            <SideBySide
                image={{
                    src: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/FeaturesCarousel/scheduling.png`,
                    alt: 'Cademy scheduling depiction',
                }}
                direction="rtl"
                heading="Schedule meetings, one-to-ones, training sessions and more"
                text="Easily schedule and organise your one to one meetings with Cademy. Sync to your Google or Outlook calendars, dynamically adjust availability, and make it easier for your customers to book time with you effortlessly."
            />
        ),
    },
    {
        key: 'webinars',
        label: 'Webinars',
        icon: <CameraHomeSolidIcon height={20} width={20} />,
        content: (
            <SideBySide
                image={{
                    src: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/FeaturesCarousel/webinars.png`,
                    alt: 'Cademy LMS depiction',
                }}
                heading="Effortlessly organise & run webinars"
                text="Create, schedule, and host engaging webinars directly from Cademy. Connect Cademy with Zoom and simplify booking, reminders and follow-ups."
                direction="rtl"
            />
        ),
    },
    {
        key: 'calendars',
        label: 'Calendars',
        icon: <CalendarsSolidIcon height={20} width={20} />,
        content: (
            <SideBySide
                image={{
                    src: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/FeaturesCarousel/calendars.png`,
                    alt: 'Cademy calendars depiction',
                }}
                direction="rtl"
                heading="Stay organised with integrated calendars"
                text="Keep track of your dates and bookings in the Cademy calendar. Sync with Google and Outlook to keep track of your schedule in your native calendar apps."
            />
        ),
    },

    {
        key: 'lms',
        label: 'LMS',
        icon: <BookOpenCoverSolidIcon height={20} width={20} />,
        content: (
            <SideBySide
                image={{
                    src: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/FeaturesCarousel/lms.png`,
                    alt: 'Cademy LMS depiction',
                }}
                heading="A powerful Learning Management System"
                text="Build, run and manage learning experiences with Cademy's intuitive LMS. Build dynamic courses, mark assessments and monitor progress, all within one flexible platform."
                direction="rtl"
            />
        ),
    },
    {
        key: 'memberships',
        label: 'Memberships',
        icon: <ArrowsRepeatSolidIcon height={20} width={20} />,
        content: (
            <SideBySide
                image={{
                    src: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/FeaturesCarousel/memberships.png`,
                    alt: 'Cademy LMS depiction',
                }}
                direction="rtl"
                heading="Run memberships and subscriptions"
                text="Offer exclusive memberships with tailored benefits, access controls, and pricing. Easily manage renewals, cancellations, and member communication, all while tracking performance in real time."
            />
        ),
    },
    {
        key: 'inbox',
        label: 'Inbox',
        icon: <InboxSolidIcon height={20} width={20} />,
        content: (
            <SideBySide
                image={{
                    src: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/FeaturesCarousel/inbox.png`,
                    alt: 'Cademy inbox screenshot',
                }}
                direction="rtl"
                heading="Handle all your enquiries in a single place"
                text="The inbox pulls together all questions, requests, reviews, assessments & more. With the full context of the contact and all their interactions at your fingertips, you can handle messages faster and better than ever."
            />
        ),
    },
    {
        key: 'emails',
        label: 'Emails',
        icon: <EnvelopeSolidIcon height={20} width={20} />,
        content: (
            <SideBySide
                animation="admin"
                sectionClass={styles.anim_section}
                animationContainerClass={styles.anim_crm_container}
                direction="rtl"
                heading="Send and manage emails with ease"
                text="Easily send and manage all your emails from one centralised platform. With simple email invite campaigns, automated confirmation and reminders, and smart contact segmentation, you can keep your audience engaged without the hassle."
            />
        ),
    },
    {
        key: 'crm',
        label: 'CRM',
        icon: <UserGroupSimpleSolidIcon height={20} width={20} />,
        content: (
            <SideBySide
                animation="admin"
                heading="All your contact interactions in a single place"
                text="View, filter & bulk email contacts with ease. See at a glance all bookings, orders, enquiries, memberships, emails, notes & more. You'll be surprised how easy it is to build better customer relationships using Cademy."
                direction="rtl"
                sectionClass={styles.anim_section}
                animationContainerClass={styles.anim_crm_container}
            />
        ),
    },
    {
        key: 'embeds',
        label: 'Embeds',
        icon: <BrowsersSolidIcon height={20} width={20} />,
        content: (
            <SideBySide
                animation="checkout"
                heading="Sell on your Cademy website or embed on your website"
                text="All your courses are automatically hosted on a self-branded min-website on Cademy, making it easier than ever to get started. Even better, embed our booking system on your website and let your customers browse and check out directly on your website."
                direction="rtl"
                sectionClass={styles.anim_section}
                animationContainerClass={styles.anim_embeds_container}
            />
        ),
    },
    {
        key: 'payments',
        label: 'Payments',
        icon: <MoneyBillWaveSolidIcon height={20} width={20} />,
        content: (
            <SideBySide
                image={{
                    src: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/FeaturesCarousel/payments.png`,
                    alt: 'Cademy payments depiction',
                }}
                direction="rtl"
                heading="Take payments quickly & safely for less"
                text="All card types and mobile payments are supported out of the box, making payments easier than ever. We also allow invoice checkouts and linking directly into your Stripe, PayPal or Klarna accounts for super fast payouts and simple admin."
            />
        ),
    },
    {
        key: 'certificates',
        label: 'Certificates',
        icon: <FileCertificateSolidIcon height={20} width={20} />,
        content: (
            <SideBySide
                image={{
                    src: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/FeaturesCarousel/certificates.png`,
                    alt: 'Cademy LMS depiction',
                }}
                direction="rtl"
                heading="Effortlessly issue certificates to your students"
                text="Create and issue certificates to your learners on course completion. Customise the design, add personalised messages, and automatically email your learners directly from Cademy."
            />
        ),
    },

    {
        key: 'reviews',
        label: 'Reviews',
        icon: <StarSolidIcon height={20} width={20} />,
        content: (
            <SideBySide
                image={{
                    src: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/FeaturesCarousel/reviews.png`,
                    alt: 'Cademy LMS depiction',
                }}
                direction="rtl"
                heading="Collect feedback and showcase reviews easily"
                text="Gather valuable feedback from your learners with built-in review tools. Highlight your best testimonials, display ratings, and respond to reviews to build trust and credibility."
            />
        ),
    },
    {
        key: 'quizzes',
        label: 'Quizzes',
        icon: <BallotCheckSolidIcon height={20} width={20} />,
        content: (
            <SideBySide
                image={{
                    src: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/FeaturesCarousel/quizzes.png`,
                    alt: 'Cademy LMS depiction',
                }}
                direction="rtl"
                heading="Test knowledge with interactive quizzes and assessments"
                text="Create custom quizzes to assess learning progress. Include multiple question types and offer instant feedback to keep learners engaged and on track."
            />
        ),
    },
    {
        key: 'vouchers',
        label: 'Vouchers',
        icon: <BadgePercentSolidIcon height={20} width={20} />,
        content: (
            <SideBySide
                image={{
                    src: `${process.env.NEXT_PUBLIC_ASSETS_URL}/images/pages/shared/FeaturesCarousel/vouchers.png`,
                    alt: 'Cademy LMS depiction',
                }}
                direction="rtl"
                heading="Boost sales with promo codes and gift cards"
                text="Create and distribute discount vouchers to incentivise new customers or reward loyal learners. What's more, you can sell gift cards directly from Cademy."
            />
        ),
    },
];

export const FeaturesCarousel: FunctionComponent = () => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(1);
    const tabsContainerRef = useRef<HTMLDivElement | null>(null);

    return (
        <div className={classNames('container', styles.sectionWrapper)}>
            <div className={styles.scroll_hints}>
                <span>←</span>
                <span>Swipe to Explore</span>
                <span>→</span>
            </div>
            <div className={styles.tabsWrapper}>
                <div className={styles.tabs_container}>
                    <div className={styles.tabs} ref={tabsContainerRef}>
                        {SECTIONS.map((section, index) => {
                            return (
                                <Tab
                                    key={section.key}
                                    label={section.label}
                                    onClick={() => setSelectedTabIndex(index)}
                                    isSelected={selectedTabIndex === index}
                                    icon={section.icon}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
            {SECTIONS[selectedTabIndex].content}
        </div>
    );
};
