'use client';

import React, { FunctionComponent, useState } from 'react';
import { useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import styles from '../styles.module.scss';

type AnimationProps = {
    animation: string;
    animationContainerClass?: string;
};

const Animation: FunctionComponent<AnimationProps> = ({ animation, animationContainerClass }) => {
    const [animationLoaded, setAnimationLoaded] = useState(false);
    const [animationData, setAnimationData] = useState<Record<string, any> | null>(null);
    const container = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        fetch(`${process.env.NEXT_PUBLIC_ASSETS_URL}/images/animations/${animation}/data.json`)
            .then((res) => res.json())
            .then((animationData) => {
                setAnimationData(animationData);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [animation]);

    useEffect(() => {
        if (!animationData) {
            return;
        }

        if (typeof window === 'undefined') {
            return;
        }
        if (!animation) {
            return;
        }
        if (!container.current) {
            return;
        }
        const animationContainer = container.current;

        try {
            const instance = lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: animationData,
                name: animation,
                rendererSettings: {
                    filterSize: {
                        width: '200%',
                        height: '200%',
                        x: '-50%',
                        y: '-50%',
                    },
                },
            });

            setAnimationLoaded(true);

            return () => {
                setAnimationLoaded(false);
                instance.destroy();
            };
        } catch (err) {
            console.error(err);
        }
    }, [animation, animationData]);

    useEffect(() => {
        if (animationLoaded) {
            lottie.play(animation);
        }
    }, [animation, animationLoaded]);

    return (
        <div className={styles.animation_wrapper} key={animation}>
            <div className={animationContainerClass} ref={container}></div>
        </div>
    );
};

export default Animation;
