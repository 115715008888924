import { FunctionComponent, SVGProps } from 'react';

export const ArrowsRepeatSolidIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            width="16"
            height="16"
            viewBox="0 0 512 512"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M1.6 213.9c-5.6 16.8 3.5 34.9 20.2 40.5s34.9-3.5 40.5-20.2C77.1 189.9 118.5 160 165.2 160l173.6 0-41.4 41.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l96-96c12.5-12.5 12.5-32.8 0-45.3l-96-96c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.7 96 165.2 96C91 96 25.1 143.5 1.6 213.9zm508.7 84.2c5.6-16.8-3.5-34.9-20.2-40.5s-34.9 3.5-40.5 20.2C434.9 322.1 393.5 352 346.8 352l-173.6 0 41.4-41.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3l96 96c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L173.3 416l173.6 0c74.2 0 140.1-47.5 163.6-117.9z" />
        </svg>
    );
};

export default ArrowsRepeatSolidIcon;
